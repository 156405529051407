import { Controller } from "@hotwired/stimulus"
import { render } from 'pug'

export default class extends Controller {
  static targets = [
    'list'
  ]

  static get shouldLoad() {
    return window.location.pathname == '/'
  }

  connect() {
    this.writeHistoryList()
  }

  toggle(event) {
    document.body.classList.toggle('history-toggled')
  }

  removeHistory(pool) {
    const historyArray = (this.history || []).filter(item => {
      return item.address != pool.address
    })

    this.writeHistory(historyArray)
  }

  writeHistory(newHistory) {
    localStorage.setItem(this.localStorageKey, JSON.stringify(newHistory.slice(0,10)))
    this.writeHistoryList()
  }

  writeHistoryList() {
    this.listTarget.innerHTML = ''
    this.listTarget.insertAdjacentHTML(
      'beforeend',
      render('section.liquidity-history')
    )

    this.listTarget.querySelector('section.liquidity-history')
    .insertAdjacentHTML('beforeend', render('h3 Liquidity History'))

    if(this.liquidityHistory.length == 0) {
      this.listTarget.querySelector('section.liquidity-history')
      .insertAdjacentHTML('beforeend', render('div ~ No History Yet ~'))
    } else {
      this.liquidityHistory.forEach(item => {
        this.fetchHistoryListItem(item).then((html) => {
          if(html) {
            this.listTarget.querySelector('section.liquidity-history')
            .insertAdjacentHTML('beforeend', html)
          }
        })
      })
    }

    this.listTarget.insertAdjacentHTML(
      'beforeend',
      render('section.positions-history')
    )

    this.listTarget.querySelector('section.positions-history')
    .insertAdjacentHTML('beforeend', render('h3 Positions History'))

    if(this.positionsHistory.length == 0) {
      this.listTarget.querySelector('section.positions-history')
      .insertAdjacentHTML('beforeend', render('div ~ No History Yet ~'))
    } else {
      this.positionsHistory.forEach(item => {
        this.fetchHistoryListItem(item).then((html) => {
          if(html) {
            this.listTarget.querySelector('section.positions-history')
            .insertAdjacentHTML('beforeend', html)
          }
        })
      })
    }
  }

  async fetchHistoryListItem(listItem) {
    let historyDate = new Date(listItem.datetime)

    if(listItem.type == 'liquidity') {
      let url = '/liquidity#' + listItem.address

      return render(`li
        a(data-action="history#toggle" href="${url}" class="history-item")
          | ${listItem.symbol} (${listItem.feeTier})
          br
          | ${listItem.address}

        time(datetime="${historyDate.toJSON()}") ${historyDate.toLocaleString()}`
      )
    } else {
      let url = '/positions#' + listItem.addresses.join(',')

      return render(`li
        a(data-action="history#toggle" href="${url}" class="history-item")
          | ${listItem.addresses.join(',')}
        time(datetime="${historyDate.toJSON()}") ${historyDate.toLocaleString()}`
      )
    }
  }

  get liquidityHistory() {
    return (JSON.parse(localStorage.getItem('liquidity-history')) || []).map(history => {
      return {...history, type: 'liquidity'}
    })
  }

  get positionsHistory() {
    return (JSON.parse(localStorage.getItem('positions-history')) || [])
  }
}

