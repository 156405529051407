import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'lightSwitcher',
    'darkSwitcher'
  ]

  connect() {
    const theme = localStorage.getItem('theme')

    switch(theme) {
      case 'light':
        this.setLightTheme()
        break;
      case 'dark':
        this.setDarkTheme()
        break;
      default:
        this.unsetTheme()
        break;
    }
  }

  toggleTheme(event) {
    let currentTheme = localStorage.getItem('theme')

    if(currentTheme == null || currentTheme == 'dark') {
      this.setLightTheme(event)
    } else {
      this.setDarkTheme(event)
    }
  }

  setDarkTheme(event) {
    localStorage.setItem('theme', 'dark');
    document.documentElement.classList.remove('_colorscheme-light')
    document.documentElement.classList.add('_colorscheme-dark')

    this.element.querySelector('.icon').textContent = 'light_mode'
  }

  setLightTheme(event) {
    localStorage.setItem('theme', 'light');
    document.documentElement.classList.remove('_colorscheme-dark')
    document.documentElement.classList.add('_colorscheme-light')

    this.element.querySelector('.icon').textContent = 'dark_mode'
  }

  unsetTheme() {
    localStorage.removeItem('theme');
    document.documentElement.classList.remove('_colorscheme-dark')
    document.documentElement.classList.remove('_colorscheme-light')

    this.element.querySelector('.icon').textContent = 'light_mode'
  }
}

